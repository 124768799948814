import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Divider, withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/seo"
import Social from "../components/social"
import Contact from "../components/contact"
import Blurb from "../components/blurb"
import Players from "../components/players"

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: "960px",
    margin: "0 auto",
  },
  logo: {
    maxWidth: 350,
    margin: "0 auto",
  },
  button: {
    margin: theme.spacing.unit,
  },
  link: { textDecoration: "none" },
  margins: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
  },
  caricature: {
    // margin: theme.spacing.unit * 2,
    width: "50%",
    margin: "auto",
  },
  marginBorder: { margin: theme.spacing.unit * 2 },
  spacer: { margin: theme.spacing.unit * 4 },
  bodyMargin: {
    // marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
  },
})

function index(props) {
  const { classes } = props

  return (
    <div className={classes.bodyMargin}>
      <SEO
        title="Fundraiser"
        keywords={[
          `Deborah Jarvis`,
          `Deb Jarvis`,
          `Dropdeadfunny`,
          "John Candy Box Theatre",
        ]}
      />
      <Grid container className={classes.root} justify="center" spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" gutterBottom>
            Drop Dead Funny 2020
          </Typography>
          <Img
            fluid={props.data.deborah.childImageSharp.fluid}
            className={classes.caricature}
          />
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            className={classes.margins}
          >
            <p>
              The Deborah Jarvis Award for Emerging Comediennes <br />
              Saturday, June 20, 2020, 8:00 PM
            </p>
          </Typography>
          <Img
            fluid={props.data.secondCity.childImageSharp.fluid}
            className={classes.caricature}
          />
          <Typography variant="subtitle1" align="center" gutterBottom>
            <p>
              The John Candy Box Theatre
              <br />
              The Second City Training Centre
              <br />
              99 Blue Jays Way, Toronto M5W 3G9
              <br />
              (416) 340 7270
            </p>
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.margins}
          >
            <p>
              We are pleased and excited to announce the 2020 Edition of{" "}
              <b>Drop Dead Funny; the Deborah Jarvis Memorial Comedy Jam</b>.
              This year, and we hope for years to come, Drop Dead Funny will be
              the excuse for presenting the first annual{" "}
              <b>Deborah Jarvis Award for Emerging Comediennes</b>, hosted and
              administered by the <b>Second City Training Centre</b>.
            </p>
            <p>
              The award is funded by donations to the{" "}
              <b>Deborah Jarvis Award Fund</b> at the{" "}
              <b>Ontario Arts Foundation</b>. Donations to support this award in
              Deborah's name may be made online at{" "}
              <a href="https://www.oafdn.ca/donations/new" target="_blank">
                {" "}
                https://www.oafdn.ca
              </a>
              . Please select <b>"The Deborah Jarvis Award Fund"</b> from the
              drop-down menu on the donation page.
            </p>
            <p>
              Drop Dead Funny, The First Annual Deborah Jarvis Award for
              Emerging Comediennes will be held Saturday, June 20, 2020, at 8:00
              PM, at the John Candy Box Theatre at the Second City Training
              Centre, at 99 Blue Jays Way, Toronto.
            </p>
            <p>
              In addition to the award ceremony, there will be performances by
              all the nominated comediennes, who will astound and amaze with
              their stage command and improv skills.
            </p>
            <p>
              So, mark your calendars. Make plans to attend. Open up your
              mobiles and donate. This isn't about us, it isn't about you; it's
              about perpetuating Deborah Jarvis' name in the annals of Canadian
              comedy.
            </p>
          </Typography>
          <Typography variant="h4" align="center" gutterBottom>
            Drop Dead Funny 2020
          </Typography>
          <Link to="/twentyNineteen">
            <Typography variant="h6" align="center" gutterBottom>
              Check out the fun from Drop Dead Funny 2019
            </Typography>
          </Link>
        </Grid>

        <Grid item xs={12} md={6} align="center">
          <a
            href="https://www.oafdn.ca/donations/new"
            target="_blank"
            className={classes.link}
          >
            <Button
              variant="contained"
              className={classes.button}
              size="large"
              fullWidth
            >
              Make a Donation Today
            </Button>
          </a>
        </Grid>

        <Grid item xs={12}>
          <Social />
        </Grid>
      </Grid>
    </div>
  )
}

index.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(index)

export const pageQuery = graphql`
  query {
    deborah: file(relativePath: { eq: "caricature2.png" }) {
      ...fluidImage
    }
    secondCity: file(relativePath: { eq: "SCTC-logo.jpg" }) {
      ...fluidImage
    }
  }
`
